<template>
  <div class="container flex">
    <div class="w-0 lg:py-14 lg:ml-4 lg:w-60">
      <ul class="relative" v-for="(item, index) in contents[activeIndex].sideBarComp" :key="index">
        <li class="relative">
          <div
            @click="activeIndex = index"
            class="flex items-center text-base lg:py-2 h-12 overflow-hidden whitespace-nowrap cursor-pointer"
            :class="{
              ['text-yellow']: activeIndex === index,
              ['font-bold']: activeIndex === index,
            }"
          >{{
              item
            }}</div>
        </li>
      </ul>
    </div>
    <div class="privacy_policy mx-4">
      <div class="container py-16 mdmax:py-10 text-justify mdmax:text-left">
        <div class="flex mdmax:flex-col justify-between mdmax:mb-4 mb-7">
          <div class="w-3/4 mdmax:w-full">
            <router-link to="/" class="text-base font-normal text-yellow cursor-pointer">
              <span class="pr-1">
                <Chevron direction="left" width="10" height="10" color="#3b82f6" />
              </span>
              {{ contents[activeIndex].backToHome }}
            </router-link>
            <!-- <div class="w-full hidden mdmax:pt-3 mdmax:inline-block">
              <div class="text-xs font-normal text-neutral-500 mb-2">{{ contents[activeIndex].selections.selectLang }}
              </div>
              <div class="relative">
                <span class="inline-block absolute right-5 top-1/2 transform -translate-y-1/2 leading-0 z-10">
                  <Chevron direction="down" width="16" height="16" color="#3b82f6" />
                </span>
                <select v-model="activeIndex"
                  class="border border-grey-field pl-3 py-3 focus:outline-none focus:ring focus:ring-tosca w-full h-10 text-xs rounded-lg appearance-none bg-white relative z-1">
                  <option :value="index" v-for="(item, index) in language" :key="'language1-' + index">
                    {{ item }}
                  </option>
                </select>
              </div>
            </div> -->

            <div class="w-full hidden mdmax:pt-3 mdmax:inline-block">
              <div class="text-xs font-normal text-neutral-500 mb-2">
                Pilih Kelas
              </div>
              <div class="relative">
                <span class="inline-block absolute right-5 top-1/2 transform -translate-y-1/2 leading-0 z-10">
                  <Chevron direction="down" width="16" height="16" color="#3b82f6" />
                </span>
                <select v-model="activeIndex"
                  class="border border-grey-field pl-3 py-3 focus:outline-none focus:ring focus:ring-tosca w-full h-10 text-xs rounded-lg appearance-none bg-white relative z-1">
                  <option :value="index" v-for="(item, index) in contents[activeIndex].sideBarComp" :key="'kelas-' + index">
                    {{ item }}
                  </option>
                </select>
              </div>
            </div>
            <div class="text-3.5xl mdmax:text-2xl font-bold text-neutral mdmax:pt-4 pt-6">{{
              contents[activeIndex].staticText.title }}</div>
          </div>
          <!-- <div class="w-1/4 mdmax:hidden">
            <div class="text-xs font-normal text-neutral-500 mb-2">{{ contents[activeIndex].selections.selectLang }}</div>
            <div class="relative w-full">
              <span class="inline-block absolute right-5 top-1/2 transform -translate-y-1/2 leading-0 z-10">
                <Chevron direction="down" width="16" height="16" color="#3b82f6" />
              </span>
              <select v-model="activeIndex"
                class="border border-grey-field pl-3 py-3 focus:outline-none focus:ring focus:ring-tosca w-full rounded-lg appearance-none bg-white relative z-1">
                <option :value="index" v-for="(item, index) in language" :key="'language2-' + index">
                  {{ item }}
                </option>
              </select>
            </div>
          </div> -->
        </div>
        <div class="text-base mdmax:text-sm font-normal text-neutral-500 pb-4"
          v-for="(bodyText, index) in contents[activeIndex].staticText.body" :key="'body-' + index">
          {{ bodyText }}
        </div>
        <div v-if="contents[activeIndex].loopTextTitle !== null" class="text-lg mdmax:text-base font-semibold text-neutral-500 pb-4">
          {{ contents[activeIndex].loopTextTitle }}
        </div>
        <div class="pb-4" v-for="(content, index) in contents[activeIndex].loopingText" :key="'loopingText-' + index">
          <div class="text-lg mdmax:text-base font-semibold text-neutral-500 mb-4">{{ content.title }}</div>
          <div v-for="(innerContent, innerIndex) in content.body" :key="`innerBody-${innerIndex}`">
            <div v-if="innerContent.type === 'text'" class="text-base mdmax:text-sm font-normal text-neutral-500 mb-4">
              {{ innerContent.content }}
            </div>
            <div v-if="innerContent.type === 'html'" v-html="innerContent.content" class="text-base mdmax:text-sm font-normal text-neutral-500 mb-4">
            </div>
            <img
              v-if="innerContent.type === 'image'"
              class="mb-4"
              :src="innerContent.url"
              :alt="innerContent.alt"
            >
          </div>
          <!-- <div class="text-base mdmax:text-sm font-normal text-neutral-500">
            {{ content.body1 }}
          </div>
          <div class="text-base mdmax:text-sm font-normal text-neutral-500 pt-3" v-if="content.body2">
            {{ content.body2 }}
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'

const linkStyle = 'color: rgb(247, 147, 30);'

export default {
  name: 'privacy-policy',
  components: {
    Chevron: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Chevron')
  },
  data: () => ({
    activeIndex: 0,
    language: ['Bahasa Indonesia', 'English'],
    contents: [
      {
        staticText: {
          title: 'Panduan Siswa Prakerja',
          body: []
        },
        loopTextTitle: 'Kelas Daring (Webinar)',
        selections: {
          selectLang: 'Pilih Bahasa'
        },
        sideBarComp: ['Daring (Webinar)', 'Asynchronous (Mandiri)', 'Luring (Offline)'],
        backToHome: 'Kembali ke Beranda',
        loopingText: [
          {
            title: 'MENGAKSES VOUCHER',
            body: [
              {
                type: 'text',
                content: `Voucher merupakan kode yang didapatkan setelah membeli kelas melalui 
                Digital Platform (DP) seperti Bukalapak, Tokopedia, Kariermu, Pintar, Pijar Mahir, dan lainnya. Kode dapat diakses melalui riwayat transaksi atau riwayat pembelian pada Digital Platform atau dapat dilihat melalui email.`
              },
              {
                type: 'image',
                url: require('@/assets/images/panduan-redeem/image_1.png'),
                alt: 'How To Access Voucher'
              }
            ]
          },
          {
            title: 'MENGAKSES KODE REDEEM',
            body: [
              {
                type: 'text',
                content: `Kode redeem merupakan kode unik berjumlah 12 digit yang dimiliki 
                oleh setiap siswa yang dikirimkan oleh Manajemen Pelaksana (PMO) Prakerja. Kode Redeem dapat diakses melalui dashboard siswa di laman prakerja masing-masing siswa atau diakses melalui e-mail.`
              },
              {
                type: 'image',
                url: require('@/assets/images/panduan-redeem/image_2.png'),
                alt: 'How To Access Redeem Code'
              }
            ]
          },
          {
            title: 'MEMBUAT AKUN SISWA DI G2ACADEMY',
            body: [
              {
                type: 'html',
                content: `Jika kamu belum memiliki akun siswa, silakan buat akun terlebih dahulu di 
                <a href="https://www.g2academy.co/register" style="${linkStyle}" target="__blank">https://www.g2academy.co/register</a>. 
                Silakan masukkan nama, email, dan nomor telepon yang benar sesuai dengan yang kamu daftarkan di prakerja. Pada bagian “select your motivation” silakan pilih “prakerja”.`
              },
              {
                type: 'image',
                url: require('@/assets/images/panduan-redeem/image_3.png'),
                alt: 'How To Access Redeem Code'
              }
            ]
          },
          {
            title: 'MASUK / LOGIN KE AKUN',
            body: [
              {
                type: 'html',
                content: `Login ke akun kamu melalui 
                <a href="https://www.g2academy.co/login#student" style="${linkStyle}" target="__blank">https://www.g2academy.co/login</a>
                menggunakan email dan password yang 
                sudah kamu daftarkan sebelumnya. Apabila kamu tidak mengingat password akun yang dibuat, klik “Forgot Password” lalu masukkan email. 
                Password baru akan kamu dapatkan melalui email.`
              },
              {
                type: 'image',
                url: require('@/assets/images/panduan-redeem/image_4.png'),
                alt: 'How To Access Redeem Code'
              }
            ]
          },
          {
            title: 'MENGAKSES HALAMAN REDEEM',
            body: [
              {
                type: 'text',
                content: `Proses redeem hanya bisa dilakukan di jam tertentu (60 menit sebelum sampai 60 menit sesudah jadwal pelatihan pertama dimulai, 
                jika melakukan redeem di luar jam tersebut maka proses redeem akan gagal). Pastikan kamu sudah berada di durasi waktu yang tepat untuk melakukan proses redeem.`
              },
              {
                type: 'html',
                content: `Akses halaman redeem di 
                <a href="https://www.g2academy.co/prakerja/redeem/" style="${linkStyle}" target="__blank">https://www.g2academy.co/prakerja/redeem/</a> 
                dan pilih lembaga penyedia pelatihan kamu`
              },
              {
                type: 'image',
                url: require('@/assets/images/panduan-redeem/image_5.png'),
                alt: 'How To Access Redeem Code'
              },
              {
                type: 'text',
                content: `Setelah memilih Lembaga Pelatihan, pilih Digital Platform tempat kamu membeli pelatihan. 
                Setelah itu masukkan kode voucher dan kode redeem. Pastikan kode tidak salah ketik.`
              },
              {
                type: 'image',
                url: require('@/assets/images/panduan-redeem/image_6.png'),
                alt: 'How To Access Redeem Code'
              }
            ]
          },
          {
            title: 'PROSES VERIFIKASI WAJAH',
            body: [
              {
                type: 'text',
                content: `Setelah klik “gunakan kode” pada proses redeem, kamu akan diarahkan ke halaman prakerja untuk melakukan verifikasi wajah. 
                Pastikan kamu berada di tempat dengan pencahayaan dan koneksi yang bagus agar proses verifikasi lancar.`
              },
              {
                type: 'image',
                url: require('@/assets/images/panduan-redeem/image_7.png'),
                alt: 'How To Access Redeem Code'
              },
              {
                type: 'text',
                content: `Setelah menginput email, password, dan angka verifikasi yang tertera, kamu akan mendapatkan kode OTP melalui email. 
                Buka inbox email untuk mengakses kode OTP dan masukkan untuk melanjutkan proses login.`
              },
              {
                type: 'image',
                url: require('@/assets/images/panduan-redeem/image_8.png'),
                alt: 'How To Access Redeem Code'
              },
              {
                type: 'image',
                url: require('@/assets/images/panduan-redeem/image_9.png'),
                alt: 'How To Access Redeem Code'
              },
              {
                type: 'text',
                content: 'Berikan izin akses dengan klik “Ya, Berikan Akses”.'
              },
              {
                type: 'image',
                url: require('@/assets/images/panduan-redeem/image_10.png'),
                alt: 'How To Access Redeem Code'
              },
              {
                type: 'text',
                content: 'Sistem akan mengarahkan untuk melakukan verifikasi wajah. Ikuti panduan yang diberikan.'
              },
              {
                type: 'image',
                url: require('@/assets/images/panduan-redeem/image_11.png'),
                alt: 'How To Access Redeem Code'
              },
              {
                type: 'text',
                content: 'Berikan izin untuk penggunaan kamera'
              },
              {
                type: 'image',
                url: require('@/assets/images/panduan-redeem/image_12.png'),
                alt: 'How To Access Redeem Code'
              },
              {
                type: 'text',
                content: 'Jika berhasil, maka sistem akan menampilkan tampilan verifikasi berhasil. Jika verifikasi gagal kamu bisa mengulanginya sebanyak maksimal 10 kali.'
              },
              {
                type: 'image',
                url: require('@/assets/images/panduan-redeem/image_13.png'),
                alt: 'How To Access Redeem Code'
              }
            ]
          },
          {
            title: 'MASUK KELAS',
            body: [
              {
                type: 'html',
                content: `Jika proses redeem berhasil, 
                maka Anda akan masuk ke halaman kelas. Kelas bisa diakses melalui 
                <a href="https://www.g2academy.co/user/my-classes" style="${linkStyle}" target="__blank">https://www.g2academy.co/user/my-classes</a>`
              },
              {
                type: 'image',
                url: require('@/assets/images/panduan-redeem/image_14.png'),
                alt: 'How To Access Redeem Code'
              },
              {
                type: 'text',
                content: 'Apabila proses redeem gagal, maka sistem akan menampilkan halaman error'
              },
              {
                type: 'image',
                url: require('@/assets/images/panduan-redeem/image_15.png'),
                alt: 'How To Access Redeem Code'
              },
              {
                type: 'html',
                content: `Apabila mengalami halaman error, harap hubungi call center prakerja melalui 
                <a href="https://bantuan.prakerja.go.id/hc/id/requests/new" style="${linkStyle}" target="__blank">https://bantuan.prakerja.go.id/hc/id/requests/new</a> 
                atau hubungi admin lembaga pelatihan melalui 
                <a href="https://www.g2academy.co/help" style="${linkStyle}" target="__blank">https://www.g2academy.co/help</a>`
              }
            ]
          },
          {
            title: 'VERIFIKASI WAJAH SETIAP PERTEMUAN',
            body: [
              {
                type: 'text',
                content: `Pada setiap pertemuan, kamu akan diarahkan untuk melakukan verifikasi wajah. 
                Klik “Verifikasi Kode” dan masukkan kode redeem kamu. Pastikan kode redeem kamu tersimpan dengan baik. Setelah klik redeem kode kamu akan diarahkan untuk verifikasi wajah di halaman prakerja seperti saat pertama kali redeem kelas.`
              },
              {
                type: 'image',
                url: require('@/assets/images/panduan-redeem/image_16.png'),
                alt: 'How To Access Redeem Code'
              },
              {
                type: 'image',
                url: require('@/assets/images/panduan-redeem/image_17.png'),
                alt: 'How To Access Redeem Code'
              }
            ]
          },
          {
            title: 'VERIFIKASI WAJAH POST-TEST',
            body: [
              {
                type: 'text',
                content: `Untuk Post-Test, kamu hanya akan bisa masuk dan mengerjakan Post-Test dan Unjuk 
                Keterampilan apabila seluruh tugas praktik mandiri sudah dikumpulkan dan diberikan feedback oleh instruktur.`
              }
            ]
          }
        ]
      },
      {
        staticText: {
          title: 'Panduan Siswa Prakerja',
          body: []
        },
        loopTextTitle: 'Kelas Belajar Mandiri (Asynchronous)',
        selections: {
          selectLang: 'Pilih Bahasa'
        },
        sideBarComp: ['Daring (Webinar)', 'Asynchronous (Mandiri)', 'Luring (Offline)'],
        backToHome: 'Kembali ke Beranda',
        loopingText: [
          {
            title: 'MENGAKSES VOUCHER',
            body: [
              {
                type: 'text',
                content: `Voucher merupakan kode yang didapatkan setelah membeli kelas melalui Digital Platform (DP) seperti 
                Bukalapak, Tokopedia, Kariermu, Pintar, Pijar Mahir, dan lainnya. Kode dapat diakses melalui akses ke DP atau dilihat melalui email.`
              },
              {
                type: 'image',
                url: require('@/assets/images/panduan-redeem/image_1.png'),
                alt: 'How To Access Voucher'
              }
            ]
          },
          {
            title: 'MENGAKSES KODE REDEEM',
            body: [
              {
                type: 'text',
                content: `Kode redeem merupakan kode unik berjumlah 12 digit yang dimiliki 
                oleh setiap siswa yang dikirimkan oleh Manajemen Pelaksana (PMO) Prakerja. Kode Redeem dapat diakses melalui dashboard siswa di laman prakerja masing-masing siswa atau diakses melalui e-mail.`
              },
              {
                type: 'image',
                url: require('@/assets/images/panduan-redeem/image_2.png'),
                alt: 'How To Access Redeem Code'
              }
            ]
          },
          {
            title: 'MEMBUAT AKUN SISWA DI G2ACADEMY',
            body: [
              {
                type: 'html',
                content: `Jika kamu belum memiliki akun siswa, silakan buat akun terlebih dahulu di 
                <a href="https://www.g2academy.co/register" style="${linkStyle}" target="__blank">https://www.g2academy.co/register</a>. 
                Silakan masukkan nama, email, dan nomor telepon yang benar sesuai dengan yang kamu daftarkan di prakerja. Pada bagian “select your motivation” silakan pilih “prakerja”.`
              },
              {
                type: 'image',
                url: require('@/assets/images/panduan-redeem/image_3.png'),
                alt: 'How To Access Redeem Code'
              }
            ]
          },
          {
            title: 'MASUK / LOGIN KE AKUN',
            body: [
              {
                type: 'html',
                content: `Login ke akun kamu melalui 
                <a href="https://www.g2academy.co/login#student" style="${linkStyle}" target="__blank">https://www.g2academy.co/login</a>
                menggunakan email dan password yang 
                sudah kamu daftarkan sebelumnya. Apabila kamu tidak mengingat password akun yang dibuat, klik “Forgot Password” lalu masukkan email. 
                Password baru akan kamu dapatkan melalui email.`
              },
              {
                type: 'image',
                url: require('@/assets/images/panduan-redeem/image_4.png'),
                alt: 'How To Access Redeem Code'
              }
            ]
          },
          {
            title: 'MENGAKSES HALAMAN REDEEM',
            body: [
              {
                type: 'html',
                content: `Akses halaman redeem di 
                <a href="https://www.g2academy.co/prakerja/redeem/" style="${linkStyle}" target="__blank">https://www.g2academy.co/prakerja/redeem/</a> 
                dan pilih lembaga penyedia pelatihan kamu`
              },
              {
                type: 'image',
                url: require('@/assets/images/panduan-redeem/image_5.png'),
                alt: 'How To Access Redeem Code'
              },
              {
                type: 'text',
                content: `Setelah memilih Lembaga Pelatihan, pilih Digital Platform tempat kamu membeli pelatihan. 
                Setelah itu masukkan kode voucher dan kode redeem. Pastikan kode tidak salah ketik.`
              },
              {
                type: 'image',
                url: require('@/assets/images/panduan-redeem/image_6.png'),
                alt: 'How To Access Redeem Code'
              }
            ]
          },
          {
            title: 'PROSES VERIFIKASI WAJAH',
            body: [
              {
                type: 'text',
                content: `Setelah klik “gunakan kode” pada proses redeem, kamu akan diarahkan ke halaman prakerja untuk melakukan verifikasi wajah. 
                Pastikan kamu berada di tempat dengan pencahayaan dan koneksi yang bagus agar proses verifikasi lancar.`
              },
              {
                type: 'image',
                url: require('@/assets/images/panduan-redeem/image_7.png'),
                alt: 'How To Access Redeem Code'
              },
              {
                type: 'text',
                content: `Setelah menginput email, password, dan angka verifikasi yang tertera, kamu akan mendapatkan kode OTP melalui email. 
                Buka inbox email untuk mengakses kode OTP dan masukkan untuk melanjutkan proses login.`
              },
              {
                type: 'image',
                url: require('@/assets/images/panduan-redeem/image_8.png'),
                alt: 'How To Access Redeem Code'
              },
              {
                type: 'image',
                url: require('@/assets/images/panduan-redeem/image_9.png'),
                alt: 'How To Access Redeem Code'
              },
              {
                type: 'text',
                content: 'Berikan izin akses dengan klik “Ya, Berikan Akses”.'
              },
              {
                type: 'image',
                url: require('@/assets/images/panduan-redeem/image_10.png'),
                alt: 'How To Access Redeem Code'
              },
              {
                type: 'text',
                content: 'Sistem akan mengarahkan untuk melakukan verifikasi wajah. Ikuti panduan yang diberikan.'
              },
              {
                type: 'image',
                url: require('@/assets/images/panduan-redeem/image_11.png'),
                alt: 'How To Access Redeem Code'
              },
              {
                type: 'text',
                content: 'Berikan izin untuk penggunaan kamera'
              },
              {
                type: 'image',
                url: require('@/assets/images/panduan-redeem/image_12.png'),
                alt: 'How To Access Redeem Code'
              },
              {
                type: 'text',
                content: 'Jika berhasil, maka sistem akan menampilkan tampilan verifikasi berhasil. Jika verifikasi gagal kamu bisa mengulanginya sebanyak maksimal 10 kali.'
              },
              {
                type: 'image',
                url: require('@/assets/images/panduan-redeem/image_13.png'),
                alt: 'How To Access Redeem Code'
              }
            ]
          },
          {
            title: 'MASUK KELAS',
            body: [
              {
                type: 'html',
                content: `Jika proses redeem berhasil, 
                maka Anda akan masuk ke halaman kelas. Kelas bisa diakses melalui 
                <a href="https://www.g2academy.co/user/my-classes" style="${linkStyle}" target="__blank">https://www.g2academy.co/user/my-classes</a>`
              },
              {
                type: 'image',
                url: require('@/assets/images/panduan-redeem/image_14.png'),
                alt: 'How To Access Redeem Code'
              },
              {
                type: 'text',
                content: 'Apabila proses redeem gagal, maka sistem akan menampilkan halaman error'
              },
              {
                type: 'image',
                url: require('@/assets/images/panduan-redeem/image_15.png'),
                alt: 'How To Access Redeem Code'
              },
              {
                type: 'html',
                content: `Apabila mengalami halaman error, harap hubungi call center prakerja melalui 
                <a href="https://bantuan.prakerja.go.id/hc/id/requests/new" style="${linkStyle}" target="__blank">https://bantuan.prakerja.go.id/hc/id/requests/new</a> 
                atau hubungi admin lembaga pelatihan melalui 
                <a href="https://www.g2academy.co/help" style="${linkStyle}" target="__blank">https://www.g2academy.co/help</a>`
              }
            ]
          },
          {
            title: 'VERIFIKASI WAJAH SETIAP PERTEMUAN',
            body: [
              {
                type: 'text',
                content: `Pada setiap pertemuan, kamu akan diarahkan untuk melakukan verifikasi wajah. 
                Klik “Verifikasi Kode” dan masukkan kode redeem kamu. Pastikan kode redeem kamu tersimpan dengan baik. Setelah klik redeem kode kamu akan diarahkan untuk verifikasi wajah di halaman prakerja seperti saat pertama kali redeem kelas.`
              },
              {
                type: 'image',
                url: require('@/assets/images/panduan-redeem/image_16.png'),
                alt: 'How To Access Redeem Code'
              },
              {
                type: 'image',
                url: require('@/assets/images/panduan-redeem/image_17.png'),
                alt: 'How To Access Redeem Code'
              }
            ]
          },
          {
            title: 'VERIFIKASI WAJAH POST-TEST',
            body: [
              {
                type: 'text',
                content: `Untuk Post-Test, kamu hanya akan bisa masuk dan mengerjakan Post-Test dan Unjuk 
                Keterampilan apabila seluruh tugas praktik mandiri sudah dikumpulkan dan diberikan feedback oleh instruktur.`
              }
            ]
          }
        ]
      },
      {
        staticText: {
          title: 'Panduan Siswa Prakerja',
          body: []
        },
        loopTextTitle: null,
        selections: {
          selectLang: 'Pilih Bahasa'
        },
        sideBarComp: ['Daring (Webinar)', 'Asynchronous (Mandiri)', 'Luring (Offline)'],
        backToHome: 'Kembali ke Beranda',
        loopingText: [
          {
            title: 'Kelas Luring (Offline)',
            body: [
              {
                type: 'html',
                content: `Untuk Kelas Luring, 
                kelas akan dilakukan secara offline dengan mekanisme absensi dan verifikasi wajah dilakukan secara langsung. 
                Harap berkoordinasi dengan admin Lembaga Pelatihan untuk akses kelas kamu melalui 
                <a href="https://www.g2academy.co/help" style="${linkStyle}" target="__blank">https://www.g2academy.co/help</a>.`
              }
            ]
          }
        ]
      }
    ]
  }),
  computed: {
    ...mapGetters('common', ['contentLanguage'])
  },
  watch: {
    activeIndex: function (value) {
      let name = 'ina'
      value === 0 ? (name = 'ina') : (name = 'en')
      this.setContentLanguage({ id: value, name })
    }
  },
  mounted() {
    this.activeIndex = this.contentLanguage.id
  },
  methods: {
    ...mapMutations('common', ['setContentLanguage'])
  }
}
</script>
